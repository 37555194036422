import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/english.scss"
const Privacy = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsEnglishpage?.seoMetaTags} page="English">
    <div className="english">
      <div className="content">
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/4807fe8e-534f-4a51-a27b-e0828ade061b/cd.js"
          type="text/javascript"
          async
        ></script>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query privacyPageQuery {
    datoCmsFrontpage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
export default Privacy
